// Type imports
import type { GetStaticProps, NextPage } from 'next'

// External imports
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { NextSeo } from 'next-seo'
import { useTranslation } from 'react-i18next'

import styled from '@emotion/styled'
import { PrimaryLinkButton } from '@outwrite/button'

// Other
import Link from '../components/NextLink'
import LogoHomeLink from '../components/LogoHomeLink'
import { HeroSection } from '../components/Hero'

/**
 * Load all the namespaces required for this page include all localized components
 */
type RequiredNs = '404'
const namespaces: Record<RequiredNs, true> = { 404: true }
export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale ?? '', Object.keys(namespaces)))
  }
})

/**
 * Styles
 */

const Wrapper = styled(HeroSection)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
`

const LogoLink = styled(LogoHomeLink)`
  align-self: flex-start;
  justify-self: flex-start;
`

const Content = styled.div`
  margin: auto 0;
  text-align: center;
`

const Custom404: NextPage = () => {
  const { t } = useTranslation('404')

  const router = useRouter()

  useEffect(() => {
    // Hack: Redirect /api to /
    if (router.asPath === '/api') {
      void router.replace('/')
    }
  })

  return (
    <>
      <NextSeo {...t('SEO')} />
      <Wrapper>
        <LogoLink />
        <Content>
          <h1>{t('TITLE')}</h1>
          <Link href='/' passHref><PrimaryLinkButton>{t('GO_HOME')}</PrimaryLinkButton></Link>
        </Content>
      </Wrapper>
    </>
  )
}

export default Custom404
